@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap");

html,
body {
  margin: 0;
  padding: 0;
  background-color: lightgray;
}

.leaflet-container {
  height: 100%;
  width: 100%;
}

.leaflet-div-icon {
  background: transparent !important;
  border: none !important;
}

.icon-sm {
  background: black;
  mask-size: cover;
  height: 30px;
  width: 30px;
}
.icon-md {
  background: white;
  mask-size: cover;
  height: 50px;
  width: 50px;
}

.warehouseIcon {
  display: inline-block;
  mask: url("./assets/icons/warehouse.svg");
}
.unitIcon {
  display: inline-block;
  mask: url("./assets/icons/unit.svg");
}
.chartIcon {
  display: inline-block;
  mask: url("./assets/icons/chart.svg");
}
.tempIcon {
  display: inline-block;
  mask: url("./assets/icons/temp.svg");
}

.powerIcon {
  display: inline-block;
  mask: url("./assets/icons/power.svg");
}

.userIcon {
  display: inline-block;
  mask: url("./assets/icons/user.svg");
}

.alertIcon {
  display: inline-block;
  mask: url("./assets/icons/alert.svg");
}

/* .status {
  height: 100%;
}

.loading {
  position: relative;
  background-color: #e2e2e2;
  z-index: 0;
  border-radius: 10px;
}

.loading::before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(200, 200, 200);
  z-index: 1;
}
.loading::after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.4),
    transparent
  );
  animation: loading 2s infinite;
  z-index: 2;
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}

.status.loading {
  max-width: 100%;
  height: auto;
  overflow: hidden;
} */
