@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap);
html,
body {
  margin: 0;
  padding: 0;
  background-color: lightgray;
}

.leaflet-container {
  height: 100%;
  width: 100%;
}

.leaflet-div-icon {
  background: transparent !important;
  border: none !important;
}

.icon-sm {
  background: black;
  -webkit-mask-size: cover;
          mask-size: cover;
  height: 30px;
  width: 30px;
}
.icon-md {
  background: white;
  -webkit-mask-size: cover;
          mask-size: cover;
  height: 50px;
  width: 50px;
}

.warehouseIcon {
  display: inline-block;
  -webkit-mask: url(/static/media/warehouse.838b29a7.svg);
          mask: url(/static/media/warehouse.838b29a7.svg);
}
.unitIcon {
  display: inline-block;
  -webkit-mask: url(/static/media/unit.92a19748.svg);
          mask: url(/static/media/unit.92a19748.svg);
}
.chartIcon {
  display: inline-block;
  -webkit-mask: url(/static/media/chart.28b7ff1d.svg);
          mask: url(/static/media/chart.28b7ff1d.svg);
}
.tempIcon {
  display: inline-block;
  -webkit-mask: url(/static/media/temp.41debde0.svg);
          mask: url(/static/media/temp.41debde0.svg);
}

.powerIcon {
  display: inline-block;
  -webkit-mask: url(/static/media/power.cc78eb9e.svg);
          mask: url(/static/media/power.cc78eb9e.svg);
}

.userIcon {
  display: inline-block;
  -webkit-mask: url(/static/media/user.f78f5e5b.svg);
          mask: url(/static/media/user.f78f5e5b.svg);
}

.alertIcon {
  display: inline-block;
  -webkit-mask: url(/static/media/alert.bae9ac98.svg);
          mask: url(/static/media/alert.bae9ac98.svg);
}

/* .status {
  height: 100%;
}

.loading {
  position: relative;
  background-color: #e2e2e2;
  z-index: 0;
  border-radius: 10px;
}

.loading::before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(200, 200, 200);
  z-index: 1;
}
.loading::after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.4),
    transparent
  );
  animation: loading 2s infinite;
  z-index: 2;
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}

.status.loading {
  max-width: 100%;
  height: auto;
  overflow: hidden;
} */

/* For Power View - d3 chart */
.node {
  cursor: pointer;
}
.node circle {
  fill: #fff;
  stroke: steelblue;
  stroke-width: 3px;
}

.node text {
  font: 16px sans-serif;
}

.link {
  fill: none;
  stroke: #000;
  stroke-width: 2px;
  stroke-dasharray: 5, 5;
}

.d3p-circle {
  position: relative;
  height: 200px;
  width: 200px;
  background-color: red;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.d3p-mtdData {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  color: #000;
  white-space: nowrap;
  z-index: 100;
}
.d3p-mtdData-tag {
  background-color: #000;
  color: #fff;
  font-size: x-small;
  padding: 4px;
}
.d3p-content {
  height: 100%;
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: relative;
}
.d3p-title {
  position: absolute;
  left: 50%;
  bottom: 10%;
  transform: translateX(-50%);
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
  margin: 5px 0;
  padding: 2px 5px;
}
.d3p-dayData {
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
  padding: 5px 10px;
  white-space: nowrap;
  color: #fff;
  font-size: large;
  background-color: white;
  border-radius: 4px;

}
.d3p-dayData-tag {
  background-color: #fff;
  color: #000;
  font-size: x-small;
  padding: 0 2px;
}
.d3p-hourData {
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -60%);
  padding: 5px 10px;
  white-space: nowrap;
  color: #fff;
  font-size: large;
  border-radius: 4px;
}
.d3p-hourData-tag {
  background-color: #fff;
  color: #000;
  font-size: x-small;
  padding: 0 2px;
}
.d3p-data-unit {
  font-size: small;
}
.d3p-data-img {
  margin-bottom: -5px;
  height: 20px;
  width: 20px;
}

/* .react-daterange-picker__wrapper{
    border:none;
} */
.MuiInput-underline:after{
    display: none;
}
.MuiInput-underline:before{
    display: none;
}
.MuiInput-input{
    padding: 0px !important;
}
