/* For Power View - d3 chart */
.node {
  cursor: pointer;
}
.node circle {
  fill: #fff;
  stroke: steelblue;
  stroke-width: 3px;
}

.node text {
  font: 16px sans-serif;
}

.link {
  fill: none;
  stroke: #000;
  stroke-width: 2px;
  stroke-dasharray: 5, 5;
}

.d3p-circle {
  position: relative;
  height: 200px;
  width: 200px;
  background-color: red;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.d3p-mtdData {
  position: absolute;
  top: -25px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  color: #000;
  white-space: nowrap;
  z-index: 100;
}
.d3p-mtdData-tag {
  background-color: #000;
  color: #fff;
  font-size: x-small;
  padding: 4px;
}
.d3p-content {
  height: 100%;
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: relative;
}
.d3p-title {
  position: absolute;
  left: 50%;
  bottom: 10%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
  margin: 5px 0;
  padding: 2px 5px;
}
.d3p-dayData {
  position: absolute;
  left: 50%;
  top: 20%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  padding: 5px 10px;
  white-space: nowrap;
  color: #fff;
  font-size: large;
  background-color: white;
  border-radius: 4px;

}
.d3p-dayData-tag {
  background-color: #fff;
  color: #000;
  font-size: x-small;
  padding: 0 2px;
}
.d3p-hourData {
  position: absolute;
  left: 50%;
  top: 60%;
  -webkit-transform: translate(-50%, -60%);
  transform: translate(-50%, -60%);
  padding: 5px 10px;
  white-space: nowrap;
  color: #fff;
  font-size: large;
  border-radius: 4px;
}
.d3p-hourData-tag {
  background-color: #fff;
  color: #000;
  font-size: x-small;
  padding: 0 2px;
}
.d3p-data-unit {
  font-size: small;
}
.d3p-data-img {
  margin-bottom: -5px;
  height: 20px;
  width: 20px;
}
