/* .react-daterange-picker__wrapper{
    border:none;
} */
.MuiInput-underline:after{
    display: none;
}
.MuiInput-underline:before{
    display: none;
}
.MuiInput-input{
    padding: 0px !important;
}